import Vue from 'vue'
import App from './App.vue'
import router from './router'


Vue.mixin({

  mounted() {
    this.ApplicationName = "ESF.Public.FrontEnd";
  }
});

new Vue({
  router,
  store: null,
  render: h => h(App)
}).$mount('#app');