import Vue from 'vue'
import Router from 'vue-router'


Vue.use(Router)


const router = new Router({
	base: process.env.VUE_APP_PUBLICPATH,
	mode: 'history',
	routes: [
		{
			path: '/',
			alias: '/WebClientMinimalRequirements',
			meta: {
				title: 'Requerimientos Minimos de Hardware y Software',
				requiresAuth: false
			},
			// route level code-splitting this generates a separate chunk (xxxx.[hash].js) for this route which is lazy-loaded when the route is visited.
			component: () => import(/* webpackChunkName: "WebClientMinimalRequirements" */ './views/public/WebClientMinimalRequirements.vue')
		},
		{
			path: '/ClickonceApplications',
			meta: {
				title: 'Aplicaciones Clickonce',
				requiresAuth: false
			},
			// route level code-splitting this generates a separate chunk (xxxx.[hash].js) for this route which is lazy-loaded when the route is visited.
			component: () => import(/* webpackChunkName: "ClickonceApplications" */ './views/public/ClickonceApplications.vue')
		},
		{
			path: '/SpecializedClickonceApplications',
			meta: {
				title: 'Aplicaciones Clickonce Especializadas',
				requiresAuth: false
			},
			component: () => import('./views/public/SpecializedClickonceApplications.vue')
		},
		{
			path: '/ExcelAddins',
			meta: {
				title: 'Excel Addins',
				requiresAuth: false
			},
			component: () => import('./views/public/ExcelAddins.vue')
		},
		{
			path: "/Health",
			meta: { IsHealthCheck: true }
		}
	]
});


router.beforeEach(VueUtils.RouterBeforeEach);


export default router;